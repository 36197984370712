// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./qcKO7eoOB-0.js";

const cycleOrder = ["F9V8QLeI5", "Y0gXimoUM"];

const serializationHash = "framer-x0EwZ"

const variantClassNames = {F9V8QLeI5: "framer-v-130ag7g", Y0gXimoUM: "framer-v-s4xdiu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Center: "center", End: "flex-end", Start: "flex-start"}

const humanReadableVariantMap = {Active: "F9V8QLeI5", Inactive: "Y0gXimoUM"}

const getProps = ({align, height, id, title, width, ...props}) => { return {...props, cciY1fspc: title ?? props.cciY1fspc ?? "vörumerki", hk9eMydVV: humanReadableEnumMap[align] ?? align ?? props.hk9eMydVV ?? "flex-start", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "F9V8QLeI5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;align?: keyof typeof humanReadableEnumMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hk9eMydVV, cciY1fspc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "F9V8QLeI5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-130ag7g", className, classNames)} data-framer-name={"Active"} layoutDependency={layoutDependency} layoutId={"F9V8QLeI5"} ref={ref ?? ref1} style={{"--3okg9f": hk9eMydVV, ...style}} {...addPropertyOverrides({Y0gXimoUM: {"data-framer-name": "Inactive"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "80px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.02em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-20608b9f-0145-4a1e-b971-ee948ebbb015, rgb(255, 255, 255)))"}}>vörumerki</motion.p></React.Fragment>} className={"framer-1v6pwp4"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"aB6Y78BPu"} style={{"--extracted-r6o4lv": "var(--token-20608b9f-0145-4a1e-b971-ee948ebbb015, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={cciY1fspc} variants={{Y0gXimoUM: {"--extracted-r6o4lv": "var(--token-965e5c17-6359-41cc-bd03-2fa4898e2c2b, rgb(34, 36, 38))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({Y0gXimoUM: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "80px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.02em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-965e5c17-6359-41cc-bd03-2fa4898e2c2b, rgb(34, 36, 38)))"}}>vörumerki</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-x0EwZ.framer-16ufaiu, .framer-x0EwZ .framer-16ufaiu { display: block; }", ".framer-x0EwZ.framer-130ag7g { align-content: var(--3okg9f); align-items: var(--3okg9f); display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 370px; }", ".framer-x0EwZ .framer-1v6pwp4 { -webkit-user-select: none; flex: none; height: auto; max-width: 440px; position: relative; user-select: none; white-space: pre-wrap; width: auto; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-x0EwZ.framer-130ag7g { gap: 0px; } .framer-x0EwZ.framer-130ag7g > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-x0EwZ.framer-130ag7g > :first-child { margin-top: 0px; } .framer-x0EwZ.framer-130ag7g > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 370
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"Y0gXimoUM":{"layout":["fixed","auto"]}}}
 * @framerVariables {"hk9eMydVV":"align","cciY1fspc":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqcKO7eoOB: React.ComponentType<Props> = withCSS(Component, css, "framer-x0EwZ") as typeof Component;
export default FramerqcKO7eoOB;

FramerqcKO7eoOB.displayName = "Item Copy";

FramerqcKO7eoOB.defaultProps = {height: 96, width: 370};

addPropertyControls(FramerqcKO7eoOB, {variant: {options: ["F9V8QLeI5", "Y0gXimoUM"], optionTitles: ["Active", "Inactive"], title: "Variant", type: ControlType.Enum}, hk9eMydVV: {defaultValue: "flex-start", options: ["flex-start", "center", "flex-end"], optionTitles: ["Start", "Center", "End"], title: "Align", type: ControlType.Enum}, cciY1fspc: {defaultValue: "vörumerki", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerqcKO7eoOB, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})